.modal {
    text-align: center;

	&__dialog {
		@include absolute-center;
		width: 400px;
		padding: 2rem 4rem 3rem;
        border-radius: 3px;
		background-color: $color-white;
		box-shadow: 0 3px 5px -1px rgba($color-black, 0.2),
			0 5px 8px 0 rgba($color-black, 0.14),
			0 1px 14px 0 rgba($color-black, 0.12);
	}

    button {
        width: 100%;
    }

    legend {
        font-size: 1rem;
    }

	&.precache-modal {
		.success-container {
			@extend .icon--primary;
			opacity: 1;
			margin: 1rem auto;
			padding: 1rem 0;
			
			svg {
				width: rem(50px);
				height: rem(50px);
			}
		}

		.cache-complete {
			color: $color-primary;
		}
	}
}