.site-footer {
    @extend .wrapper;
    padding-top: rem(48);
    background: $color-grey-400;
    color: #FFF;

    &__info {

        a {
            color: #FFF;
        }
    }
}