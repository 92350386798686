// Adobe Typekit
@import url("https://use.typekit.net/afy5ufa.css");

body,
button,
input,
select,
optgroup,
textarea {
	font-size: 1rem;
	line-height: 1.5;
	font-family: $font__main;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@import "headings";
@import "copy";
