.app {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;

    // Adjustments for when the viewer is embedded in an iframe on the marketing site
    &.embed {
        .main-header {
            display: none;
        }

        .page-wrapper {
            height: 100%;
            margin-top: 0;
        }

        .metadata__summary {
            justify-content: flex-end;
        }

        button {
            &.back-to-gallery,
            &.full-screen {
                display: none;
            }
        }
    }
}

.main {
    background: linear-gradient(0deg, rgba($color-navy, 0) 70%, $color-silver-100 100%);

    &:before {
        @include element-overlay;
        content: "";
        background: url(../../assets/images/background.svg) no-repeat;
        background-position: top right;
        background-size: 200%;
    }

    position: relative;
    flex-grow: 1;
}