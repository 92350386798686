.viewer-container {
    @extend .wrapper;
    z-index: 0;
    position: relative;
    height: 100%;
    padding: 0;
    padding-right: 0 !important; // Prevent MUI popper components from breaking the layout
    background-color: $color-black;
}

.viewer {
    height: 100%;
    overflow: hidden;
    position: relative;

    input {
        font-size: 1.5rem;
        text-align: center;
    }

    button {
        display: inline-block;
        z-index: $z-tooltip;
        position: relative;
    }

    &__overlay {
        @include element-overlay;
        display: grid;
        grid-template-rows: 1fr auto;
    }

    &__bottombar {
        display: flex;
        z-index: $z-appbar;
        position: absolute;
        bottom: 0;
        left: 0;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: rem(10px);
        background-color: $color-black;

        @include media("<md") {
            flex-direction: column;
        }
    }

    &__control-group {
        display: flex;

        @include media("<md") {
            justify-content: center;
            width: 100%;
            gap: 1rem;

            &.zoom-controls {
                flex-direction: row-reverse;
            }

            &.sequence-controls,
            &.slider-controls {
                order: -1;

                .input-group {
                    width: inherit;
                }
            }
        }
    }

    .navigator {
        z-index: $z-appbar;

        @media (max-height: map-get($breakpoints, "md")) and (orientation: portrait) {
            display: none !important;
        }

        @media (max-height: map-get($breakpoints, "sm")) and (orientation: landscape) {
            display: none !important;
        }

        @media (max-width: map-get($breakpoints, "sm")) {
            display: none !important;
        }
    }

    .MuiSlider-root {
        position: absolute;
        top: -8px;
        left: 0;
        padding: 0;
        color: $color-primary;
    }
}