// Brand Colors
//==================================================================*/
$color-green: #20CC4B;
$color-teal: #0E6C67;
$color-navy: #060F24;
$color-white: #FFF;
$color-black: #000;
$color-silver-400: rgba($color-navy, .7);
$color-silver-300: rgba($color-navy, .5);
$color-silver-200: rgba($color-navy, .3);
$color-silver-100: rgba($color-navy, .1);


// Theme Colors
//==================================================================*/
$color-primary: $color-green;
$color-secondary: $color-teal;
$color-dark: $color-navy;
$color-light: $color-silver-300;


// Theme Color Map
//==================================================================*/
$theme-colors: (
    "primary": $color-primary,
    "secondary": $color-secondary,
    "dark": $color-dark,
    "light": $color-light,
    "white": $color-white,
    "black": $color-black,
);


// Solid Grey Colors
//==================================================================*/
$color-grey-400: #505665;
$color-grey-300: #828282;
$color-grey-200: #b3b6bc;
$color-grey-100: #e5e6e8;


// Alert Colors
//==================================================================*/
$color-danger: #f44336;
$color-success: #4CAF50;
$color-info: #2196F3;
$color-warning: #ff9800;


// Font Weights
$weight-thin: 100;
$weight-extra-light: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-extra-bold: 800;
$weight-black: 900;


// Base Font(s)
$font__main: source-sans-pro, sans-serif;
$font__headings: montserrat, sans-serif;


// Drop Shadow / Outer Glow
// Usage >> box-shadow: $outer-glow;
$outer-glow: $color-silver-100 0 0 8px;


// Site Dimensions
$site__width: 1440px;
$site__header__height: 162px;
$site__header__height__mobile: 65px;
$site__header__border-width: 2px;


// Transition
//==================================================================*/
// Usage: transition: all $animation-average $base-animation-easing;
$animation-fast: 100ms;
$animation-average: 300ms;
$animation-slow: 500ms;
$base-animation-easing: cubic-bezier(.62,.01,.37,.99);


// Z-Index Levels
$z-base: 1000;
$z-appbar: 1100;
$z-modal: 1300;
$z-tooltip: 1500;