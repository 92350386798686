.scalebar {
    position: absolute;
    top: rem(-75px);
    left: rem(25px);
    min-width: 100px;
    height: 1rem;
    border-right: solid 4px $color-primary;
    border-left: solid 4px $color-primary;
    background-color: transparent;
    transition-duration: 0.75s;
    
    &:before {
        @include absolute-center;
        width: 100%;
        height: 4px;
        content: "";
        background-color: $color-primary;
    }

    figcaption {
        display: block;
        margin-top: -2.75em;
        color: $color-primary;
        font-weight: $weight-semi-bold;
        font-size: rem(22px);
        line-height: rem(60px);
        letter-spacing: 1px;
    }
}