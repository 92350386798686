@charset 'utf-8';

// Hide Off Screen
// Usage: @include hide-off-screen;
// Position an element off-screen for screen-readers.
@use "sass:math";

// Position an element vertically and horizontally centered
// Note: parent element should use position: relative;
@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

// Position an element vertically centered
// Note: parent element should use position: relative;
@mixin vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// Position an element horizontally centered
// Note: parent element should use position: relative;
@mixin horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

// Overlay an element with a pseudo element
// Example Usage: button--icon::before { @include element-overlay }
// Note: parent element should use position: relative;
@mixin element-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Constrain proportions of an element
// Example Usage: @include aspect-ratio(4,3)
@mixin aspect-ratio($width, $height) {
    $aspect-ratio: calc(math.div($height, $width) * 100%);
    position: relative;
    width: 100%;

    &:before {
        display: block;
        padding-top: $aspect-ratio;
        content: "";
    }

    >* {
        @include element-overlay;
    }
}

// Truncate text after specified number of rows
// Example Usage: @include line-clamp(2)
@mixin line-clamp($rows) {
    display: -webkit-box;
    -webkit-line-clamp: $rows;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Special Underline for inline elements (better than text-decoration: underline)
@mixin underline($bgcolor: get-color(charcoal)) {
    background-image: linear-gradient(to right, $bgcolor 0%, $bgcolor 100%);
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 100% 2px;
}

/// Strips the unit from a number.
///
/// @param {Number (With Unit)} $value
///
/// @example scss - Usage
///   $dimension: strip-units(10em);
///
/// @example css - CSS Output
///   $dimension: 10;
///
/// @return {Number (Unitless)}

@function strip-units($value) {
    @return math.div($value, $value * 0 + 1);
}


// Convert pixels to rems
// eg. for a relational value of 12px write rem(12)
// Assumes $em-base is the font-size of <html>
$em-base: 16px !default;

@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }

    $base: $em-base;
    @if not unitless($base) {
        $base: strip-units($base);
    }

    @return math.div($pxval, $base) * 1rem;
}