.progress-bar {
    height: rem(8px);
    color: $color-primary !important;

    &.playback {
        left: 15px;
        width: calc(100% - 40px);
    }

    .MuiSlider {
        &-track {
            height: rem(8px);
            border-radius: rem(4px);
        }

        &-rail {
            opacity: 0.6;
            height: rem(8px);
            border-radius: rem(4px);
        }

        &-thumb {
            width: rem(24px);
            height: rem(24px);
            margin-top: rem(-8px);
            margin-left: rem(-12px);
            border: solid 2px currentColor;
            background-color: $color-white;

            &:focus,
            &:hover,
            &:active {
                box-shadow: inherit;
            }

            &:hover {
                cursor: grab;
            }

            &:active {
                cursor: grabbing;
            }
        }

        &-value {
            &Label {
                left: calc(-50% + 4px);
            }
        }

        &-mark {
            display: none;

            &Label {
                font-weight: $weight-regular;
                text-transform: uppercase;
            }

            &LabelActive {
                display: block;
                margin-top: 3px;
                font-weight: $weight-bold;
            }
        }
    }
}

.vertical-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    overflow: hidden;
    text-transform: uppercase;
    gap: 1rem;

    h3 {
        display: flex;
        line-height: 1.2;
        flex-direction: column;
        margin: 0;
        font-weight: $weight-bold;
        text-align: center;
    }

    .playback-speed-slider.progress-bar {
        height: rem(200px);
        width: 8px;

        .MuiSlider {
            &-track {
                width: rem(8px);
                border-radius: rem(4px);
            }

            &-rail {
                opacity: 0.6;
                width: rem(8px);
                border-radius: rem(4px);
            }

            &-thumb {
                width: rem(24px);
                height: rem(24px);
                margin-top: rem(-8px);
                margin-left: rem(-9px);
                border: solid 2px currentColor;
                background-color: $color-white;

                &:hover {
                    cursor: grab;
                }

                &:active {
                    cursor: grabbing;
                }
            }

        }
    }
}