a {
	color: $color-dark;
	text-decoration: none;
	transition: color $animation-average $base-animation-easing;

	&:visited {
		color: $color-dark;
	}

	&:hover,
	&:focus,
	&:active {
		color: lighten($color-dark, 20%);
		text-decoration: none;
	}

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}
