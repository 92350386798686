@mixin icon-button($color: $color-dark) {
    width: rem(50px);
    height: rem(50px);
    background-color: transparent;
    transition: all 500ms;

    &:hover,
    &:focus {
        cursor: pointer;
    }

    >svg {
        font-size: 2rem;
        vertical-align: middle;
    } 
}

@mixin icon-button-outline-hover($color: $color-primary) {
    outline: $color solid 10px;
    background-color: darken($color, 10%);

    &:before {
        border-color: $color;
    }

    >svg {
        color: $color-white;
    }
}

.icon-button {
    @include icon-button;
    border: none;

    &:hover {
        @include icon-outline-alt($color-primary, $color-white);
    }

    @each $variant,
    $value in $theme-colors {
        &.icon--#{$variant} {
            // Example Usage: <button className="icon-button icon--primary"></button>
            @include icon-button($value);

            &:hover {
                @include icon-outline-alt($color-primary, $value);
            }

            &.icon--outline {
                // Example Usage: <button className="icon-button icon--secondary icon--outline"></button>
                &:hover {
                    @include icon-button-outline-hover($value);
                }
            }
        }
    }

    &.icon--outline {
        // Example Usage: <button className="icon-button icon--outline"></button>
        border: 3px solid;
        border-radius: 50%;

        &:hover {
            @include icon-button-outline-hover($color-dark);
        }
    }

    &.active {
        // Example Usage: <button className="active icon-button"></button>
        color: $color-primary;

        &:hover {
            @include icon-outline-alt($color-primary, $color-primary);
        }
    }
}