input,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
    padding: rem(12px);
    border: 1px solid $color-silver-200;
    border-radius: 3px;
    color: $color-dark;
    font-weight: $weight-regular;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.02em;

    &::placeholder {
        margin-left: rem(12px);
        color: $color-silver-200;
        font-style: italic;
    }
}

input[type="search"] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
}


select {
    padding: rem(12px);
    border: 1px solid $color-silver-200;
    border-radius: 1px;
    color: $color-dark;
    font-weight: $weight-regular;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.02em;
}

textarea {
    width: 100%;
}

fieldset {
    border: 0;
}

.input-group {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: 1rem;
}