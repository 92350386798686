.main-header {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    border: solid;
    border-width: 0 0 2px;
    border-image-source: linear-gradient(90deg, $color-primary, $color-white);
    border-image-slice: 2;
    background: $color-white;

    @include media(">lg") {
        position: relative;
    }

    &__logo {
        display: flex;
        z-index: 1;
        position: fixed;
        width: 130px;

        @include media(">lg") {
            width: 230px;
            position: relative;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    a {
        transition: all .4s ease-in-out;

        &:active,
        &:focus {
            color: $color-secondary;
        }
    }

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        padding-right: rem(20px);
        padding-left: rem(20px);
        margin-inline: auto;

        @include media(">lg") {
            justify-content: flex-start;
            height: $site__header__height;
        }

        &__links {
            display: none;

            @include media(">lg") {
                display: block;
                margin-left: auto;
            }

            &__primary {
                a {
                    font-size: rem(20px);
                }
            }

            &__secondary {
                margin-top: 22px;

                a {
                    color: $color-grey-300;
                    font-size: rem(14px);
                }
            }

            ul {
                display: flex;
                justify-content: flex-end;

                li+li {
                    margin-left: 60px;
                }

                a, button {
                    position: relative;
                    font-weight: $weight-semi-bold;
                    font-family: $font__headings;
                    text-transform: uppercase;
                    letter-spacing: .04em;

                    &:after {
                        opacity: 0;
                        position: absolute;
                        right: 0;
                        bottom: -7px;
                        left: 0;
                        height: 2px;
                        content: "";
                        background: $color-primary;
                        transition: all .4s ease-in-out;
                    }

                    &:focus,
                    &:hover {
                        text-decoration: none;
                    }

                    &.active:after,
                    &:focus:after,
                    &:hover:after {
                        opacity: 1;
                    }
                }

            }

        }
    }

    .social-links {
        li {
            display: inline-block;

            +li {
                margin-left: rem(28px);
            }
        }

        a,
        svg {
            display: block;
            padding-block: 0;
        }

        a {
            opacity: .4;
            width: 24px;

            &:focus,
            &:hover {
                opacity: 1;
            }
        }

        path {
            fill: currentColor;
        }
    }

    .bottom-curve {
        display: none;
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        width: 100%;
        pointer-events: none;

        @include media(">lg") {
            display: block;
        }

        .viewer-page & {
            display: none;
        }
    }
}

.sidebar {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100vw - rem(48px));
    height: 100%;
    padding: rem(84px) rem(20px) rem(23px);
    overflow-y: auto;
    background: $color-white;
    box-shadow: 0 0 7px rgba(0, 0, 0, .45);
    transition: all .4s ease-in-out;
    transform: translateX(-100%);
    will-change: transform;

    @include media(">lg") {
        display: none;
    }

    a {
        display: block;
        font-weight: $weight-medium;
        font-size: rem(15px);
        line-height: rem(24px);
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        letter-spacing: .03em;
        padding-block: 17px;

        &:active,
        &:focus,
        &:hover {
            text-decoration: underline;
            color: $color-secondary;
        }

        @include media(">lg") {
            font-size: rem(19px);
            line-height: rem(32px);
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-bottom: 2rem;

        &__main-nav {
            flex-grow: 1;
        }

        &__auth-nav {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
    }

    .client-name {
        color: $color-dark;
        display: inline-block;
        font-weight: $weight-medium;
        font-size: rem(15px);
        line-height: rem(24px);
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        letter-spacing: .03em;
        padding-top: rem(15px);
    }

    .logout-button {
        color: $color-dark;
        font-weight: $weight-medium;
        font-size: rem(15px);
        line-height: rem(24px);
        font-family: Montserrat, sans-serif;
        text-transform: uppercase;
        letter-spacing: .03em;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .sidebar--expanded & {
        transform: translate(0);
    }
}

.hamburger-button {
    height: 36px;
    margin-right: rem(-11px);
    margin-left: auto;
    padding: rem(6px);
    border: 0;
    cursor: pointer;
    user-select: none;
    background: transparent;
    transition: all .2s ease-in-out;

    @include media(">lg") {
        display: none;
    }

    &__icon {
        & {
            position: relative;
        }

        &,
        &:after,
        &:before {
            display: block;
            width: 18px;
            height: 2px;
            border-radius: 2px;
            background: $color-dark;
            transition: all .2s ease-in-out;
        }

        &:after,
        &:before {
            position: absolute;
            left: 0;
            content: "";
            transform-origin: 1.2857142857px center;
        }

        &:before {
            top: 6px;
        }

        &:after {
            top: -6px;
        }

        .sidebar--expanded & {
            background-color: transparent;

            &:after,
            &:before {
                top: 0;
                width: 18px;
                transform-origin: 50% 50%;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

    }
}

.client-name {
    position: relative;
    color: $color-grey-300;
    font-weight: $weight-semi-bold;
    font-size: rem(14px);
    font-family: $font__headings;
    text-transform: uppercase;
    letter-spacing: .04em;

    &:after {
        opacity: 1;
        position: absolute;
        right: 0;
        bottom: -7px;
        left: 0;
        height: 2px;
        content: "";
        background: $color-primary;
        transition: all .4s ease-in-out;
    }
}

.logout-button {
    position: relative;
    display: flex;
    padding-right: 0;
    padding-left: 0;
    border: none;
    color: $color-grey-300;
    font-weight: $weight-semi-bold;
    font-size: rem(14px);
    font-family: $font__headings;
    text-transform: uppercase;
    letter-spacing: .04em;
    background-color: transparent;
    gap: 0.5rem;
    cursor: pointer;
    align-items: center;

    &_icon {
        margin-top: -1px;
    }

    >svg {
        height: rem(20px);
        width: rem(20px);
    }
}