.colorbar {
    display: flex;
    z-index: $z-base;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 125px;
    padding-bottom: 125px;
    background-color: transparent;
    transition: all 0.5s;

    @include media("<sm") {
        padding-bottom: 200px;
    }

    &.show {
        height: 100%;
    }

    &.hide {
        height: 0;
    }

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }

    img {
        height: 900px;
        max-height: 100%;
    }

    &__popover-container {
        display: flex;
        justify-content: flex-end;
        padding: 0 rem(10px);
        overflow: hidden;
    
        @include media("<xs") {
            display: none;
        }
    }
    
    &__popover-menu {
        overflow: hidden;
        padding: 0.5rem;
        margin: 0;
        list-style-type: none;
    
        hr {
            margin: 0.5rem 0;
        }
    }
}
