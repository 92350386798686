@mixin button($color: $color-dark) {
    display: flex;
    padding: rem(12px) rem(24px);
    border: none;
    color: $color;
    font-style: normal;
    font-weight: $weight-semi-bold;
    font-size: rem(16px);
    line-height: rem(20px);
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.12em;
    background-color: transparent;
    transition: all $animation-average $base-animation-easing;
    transition-property: background, border-color, outline;
    place-content: center;
    place-items: center;

    &:hover {
        cursor: pointer;
    }

    &[disabled] {
        color: $color-grey-100;
        opacity: 0.5;

        &:hover {
            cursor: not-allowed;
        }
    }
}

@mixin button-outline($color: $color-primary) {
    @include button($color);
    border: solid 2px;
    border-color: $color;
    color: $color;
    background: none;

    &:hover {
        background: $color;
        border-color: $color;
        color: $color-white;
    }

    &:focus {
        outline: darken($color, 10%) auto 1px;
        background: $color;
        border-color: $color;
        color: $color-white;
    }
}

.button,
a.button {
    // Example Usage: <button className="button"></button>
    @include button;

    &:hover {
        @include underline($color-primary);
        outline: none;
        color: $color-primary;
        transition-duration: 200ms;
    }

    &:active,
    &:focus {
        outline: none;
        color: $color-primary;
    }

    &.button--large {
        font-size: rem(22px);
        line-height: 1;
    }

    @each $variant,
    $value in $theme-colors {
        &--#{$variant} {
            // Example Usage: <button className="button--primary"></button>
            @include button($value);
            border: solid 2px $value;
            border-radius: rem(16px) 1px;
            color: $color-white;
            background-color: $value;
            transition: all .4s ease-in-out;
            
            &:hover,
            &:focus {
                border-radius: rem(40px) 1px;
            }

            &.button--outline {
                // Example Usage: <button className="button--secondary button--outline"></button>
                @include button-outline($value);

                &:hover,
                &:focus {
                    background-color: darken($value, 10%);

                    >svg {
                        color: $color-white;
                    }
                }
            }

            &.button--large {
                font-size: rem(22px);
                line-height: 1;
            }
        }
    }
}