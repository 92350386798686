.login {
    display: flex;
    position: relative;
    top: 0;
    justify-content: center;
    width: 100%;
    max-width: map-get($breakpoints, "sm");
    margin-top: $site__header__height;
    padding: rem(80px) 0;
    background-color: $color-white;
    transition: top 500ms;

    @include media(">sm") {
        @include horizontal-center;
        top: min(15%, rem(120px));
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__heading {
        @extend .heading-large;
        display: flex;
        align-items: center;
        margin-top: 0;
        gap: 1rem;

        >img {
            @include rfs(60px, height);
        }
    }

    &__username,
    &__password {
        @extend .input-group;
        @extend .flex-col;
        width: 100%;
        gap: .5rem;
    }

    &__submit {
        width: 100%;
        margin-top: 2rem;
    }
}