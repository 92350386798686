h1,
h2,
h3,
h4,
h5,
h6,
[class^="heading-"] {
    margin-top: rem(24);
    margin-bottom: rem(16);
    font-style: normal;
    font-weight: $weight-regular;
    font-family: $font__headings;
}

.heading-large {
    color: $color-black;
    font-weight: $weight-regular;
    @include font-size(75px);
    @include rfs(82px, line-height);
    text-transform: uppercase;
    letter-spacing: 0.04em;
}

.heading-medium {
    color: $color-black;
    font-weight: $weight-medium;
    @include font-size(32px);
    line-height: rem(42px);
    letter-spacing: 0.04em;
}

.heading-small {
    font-weight: $weight-semi-bold;
    @include font-size(24px);
    line-height: rem(32px);
    letter-spacing: 0.06em; 
    text-transform: uppercase;
    color: $color-black;
}

.heading-xsmall {
    font-weight: $weight-bold;
    @include font-size(16px);
    line-height: rem(34px);
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: $color-dark;
}

/* H4 (Callout Copy) */
.heading-callout-copy {
    font-weight: $weight-medium;
    @include font-size(19px);
    line-height: rem(32px);
    letter-spacing: 0.03em;
    color: $color-black;
}

