@mixin button-spinner-size {
    width: rem(20px) !important;
    height: rem(20px) !important;
    margin-right: 0.5em;
}


.spinner-container {
    position: relative;
    width: 100%;
    min-height: 100px;
}

.loading-spinner {
    @include absolute-center;
    right: auto;
    bottom: auto;
    width: auto;
    height: auto;
    color: $color-primary;
}

.MuiCircularProgress {
    &-colorPrimary {
        .loading-spinner & {
            color: $color-primary;
        }

        button &,
        .button & {
            @include button-spinner-size;
            color: $color-primary;
        }

        .button--primary & {
            @include button-spinner-size;
            color: $color-white;
        }
    }

    &-colorSecondary {
        .loading-spinner & {
            color: $color-secondary;
        }

        button &,
        .button & {
            @include button-spinner-size;
            color: $color-secondary;
        }

        .button--secondary & {
            @include button-spinner-size;
            color: $color-white;
        }
    }
}