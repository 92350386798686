p {
    margin-bottom: 1.5em;
}

blockquote {
    margin: 0 1.5em;
}

// Body Copy
.copy {
    color: $color-dark;
    font-style: normal;
    font-weight: $weight-regular;
    font-size: rem(16px);
    line-height: rem(26px); /* or 162% */
    letter-spacing: 0.02em;
}

// Body Copy (Large)
.large-copy {
    color: $color-dark;
    font-style: normal;
    font-weight: $weight-regular;
    font-size: rem(18px);
    line-height: rem(28px); /* or 156% */
}

// Technical Illustration Subtitle
.illustration-subtitle {
    color: $color-black;
    font-style: italic;
    font-weight: $weight-regular;
    font-size: rem(12px);
    line-height: rem(18px); /* or 150% */
    letter-spacing: 0.05em;
}

// CTA Button Text
@mixin cta-text {
    color: $color-dark;
    font-style: normal;
    font-weight: $weight-semi-bold;
    font-size: rem(22px);
    line-height: rem(60px); /* or 273% */
	text-transform: uppercase;
    letter-spacing: 0.12em;
}