.flex {
    &-col {
        display: flex;
        flex-direction: column;
    }

    &-row {
        display: flex;
        flex-direction: row;
    }
}

.color,
.text {
    @each $variant,
    $value in $theme-colors {
        &-#{$variant} {
            color: $value;
        }
    }
}

.bg {
    @each $variant,
    $value in $theme-colors {
        &-#{$variant} {
            background-color: $value;
        }
    }
}

.hidden {
    display: none;
}