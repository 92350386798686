.curtain-handle {
    @include icon-outline($color-white);
    @include absolute-center;
    opacity: 0.7;
    z-index: $z-tooltip;
    margin-top: -25px;
    margin-left: -25px;
    border-color: $color-white;
    cursor: grab;
    background-color: $color-black;
    box-shadow: 0 0 10px 6px $color-silver-200, 0 0 10px $color-silver-100;

    &:hover {
        border-color: $color-primary;
    }

    &:active {
        opacity: 0.8;
        border-color: $color-primary;
        cursor: grabbing;
    }

    &__icon {
        color: $color-white;
    }
}