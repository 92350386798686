.wrapper {
    position: relative;
    width: $site__width;
    min-width: 320px;
    max-width: 100%;
    margin: 0 auto;
}

.page-wrapper {
    @extend .wrapper;
    height: 100%;

    @include media("<=lg") {
        // Account for the mobile header height since it has fixed position
        height: calc(100% - $site__header__height__mobile);
        margin-top: $site__header__height__mobile;
    }
}