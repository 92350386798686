.gallery {
    @extend .wrapper;
    padding: 0 2rem 2rem;

    &__header {
        @include rfs(745px, max-width);
        margin-top: $site__header__height;

        @include media("<=lg") {
            margin-top: calc($site__header__height__mobile + 4rem);
        }

        h1 {
            @extend .heading-large;
            color: $color-primary;
            font-weight: 300;
        }

        p {
            @extend .heading-callout-copy;
        }
    }

    &__search {
        @extend .input-group;
        padding: 2rem 0;

        @include media("<sm") {
            flex-direction: column;
        }

        &__refinements {
            display: flex;
            gap: 1rem;
        }

        .ais-SearchBox {
            &-form {
                display: flex;
                gap: rem(10px);
            }

            &-input {
                flex-grow: 1;
            }

            &-submit {
                @extend .button--primary;

                &Icon {
                    color: $color-white;
                    height: 100%;
                }
            }

            &-reset {
                @extend .icon--small;
                border: none;
                cursor: pointer;
                background-color: transparent;
            }

            @include media(">=md") {
                .search-icon {
                    display: none;
                }
            }

            @include media("<md") {
                .search-text {
                    display: none;
                }

                .ais-SearchBox-submit {
                    // SVGs don't handle padding consistently across browsers, so use a margin instead.
                    // Otherwise, the padding will squish the search icon in some browsers.
                    padding: 0;

                    >svg {
                        margin: 0.75rem 1.5rem;
                    }
                }
            }
        }

        .ais-MenuSelect,
        .ais-SortBy {
            flex-grow: 1;

            &-select {
                height: 100%;
                width: 100%;
            }
        }
    }

    ul {
        display: grid;
        margin: 0;
        padding: 0;
        list-style-type: none;
        grid-template-columns: repeat(4, 1fr);
        gap: rem(30px);

        @include media("<xl") {
            grid-template-columns: repeat(3, 1fr);
            gap: rem(15px);
        }

        @include media("<md") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media("<sm") {
            grid-template-columns: 1fr;
        }

        li>.gallery__item {
            @include aspect-ratio(9, 8);
            display: grid;
            position: relative;
            max-width: 400px;
            overflow: hidden;
            cursor: pointer;
            background-color: $color-dark;

            >* {
                grid-area: 1 / -1;
            }

            .loading-spinner {
                // Increase selector specificity to ensure correct positioning
                @extend .loading-spinner;
            }

            &:hover {
                .overlay {
                    &__content {
                        flex-grow: 1;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        height: 100%;
                        background-color: $color-silver-400;
                        transition-duration: 0.75s;
                    }

                    &__title {
                        @include line-clamp(2);
                        top: 20px;
                        align-items: center;
                        order: 0;
                        max-height: rem(48px);
                        margin-top: 1rem;
                        color: $color-white;
                        transform: translateY(-1rem);
                    }

                    &__description {
                        @include line-clamp(4);
                        opacity: 1;
                        position: initial;
                        order: 1;
                        max-height: rem(96px); // line-height of 24px * 4 = 96px
                        margin-bottom: rem(50px);
                        overflow-y: hidden;
                        font-size: initial;
                        transform: translateY(0);

                        @include media("<xxl") {
                            @include line-clamp(3);
                        }

                        @include media("<lg") {
                            @include line-clamp(2);
                        }
                    }

                    &__icon {
                        position: absolute;
                        right: 1rem;
                        bottom: .5rem;
                        order: 3;
                        transform: rotate(180deg);
                    }
                }

                .thumbnail {
                    transition: all 2s;
                    transform: scale(1.5);
                }
            }

            &.placeholder-image {
                background-color: $color-dark;

                .thumbnail {
                    @include absolute-center;
                    height: 75px;
                    width: 75px;
                }
            }
        }

        .ais-Hits-item:empty {
            display: none;
        }
    }

    &__brand {
        @include aspect-ratio(9, 8);
        display: flex;
        align-items: center;
        align-content: center;
        color: $color-white;
        background-color: $color-dark;

        &__content {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            gap: 1rem;

            .logo {
                width: 110px;
                max-width: 50%;
            }

            .logotype {
                @extend .heading-small;
                margin: 0;
                color: $color-white;
                font-weight: $weight-regular;
                text-align: center;
                text-transform: initial;
            }
        }

    }

    .thumbnail {
        width: 100%;
        height: 100%;
        transition: all 1s;
        object-fit: cover;
    }

    .overlay {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background-image: linear-gradient(to bottom, transparent 50%, $color-silver-400);

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: .5rem 1rem;
            color: #FFF;
        }

        &__icon {
            margin: rem(10px) 0;
            transition: all 0.5s;
            transform: rotate(0);

            >svg {
                color: $color-white;
            }
        }

        &__title {
            @extend .heading-xsmall;
            @include line-clamp(2);
            margin: 0;
            color: $color-white;
            line-height: 150%;
            transition: transform 0.5s, color 0.5s;
        }

        &__description {
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            margin: 0;
            color: $color-white;
            font-size: 0;
            transition: opacity 0.5s, transform 0.5s;
            transform: translate3d(0, 40px, 0);
        }
    }
}