.metadata {
    $topbar__height: 86px;
    $bottombar__height: 70px;
    $bottombar__height__mobile: 120px;

    display: grid;
    grid-template-rows: auto 1fr;
    height: calc(100% - $bottombar__height);
    overflow-y: auto;
    overflow-x: hidden;

    @include media("<sm") {
        height: calc(100% - $bottombar__height__mobile);
    }

    &__summary {
        display: flex;
        z-index: $z-appbar;
        position: relative;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: rem(10px);
        background-color: $color-black;
    }

    &__title {
        display: flex;
        align-items: center;
        color: $color-white;
        text-align: right;
        cursor: pointer;
        gap: 1rem;

        h1 {
            @extend .heading-medium;
            @include line-clamp(1);
            margin: 0;
            color: inherit;
        }

        h4 {
            margin: 0;

            &:hover {
                color: $color-primary;
            }
        }
    }

    &__details {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        overflow-y: hidden;
        position: relative;
    }

    button.toggle-details {
        @extend .icon-button;
        @extend .icon--white;
        transition: all 0.5s;
        transform: rotate(0deg);

        &.expanded {
            transform: rotate(180deg);
        }
    }
}

.panel {
    display: flex;
    opacity: 0;
    visibility: hidden;
    z-index: $z-tooltip;
    position: relative;
    flex-direction: column;
    margin: 0;
    padding: 1rem 2rem;
    color: $color-white;
    text-align: right;
    background: linear-gradient(180deg, $color-black 0%, rgba($color-navy, 0) 150%);
    transition: all 0.5s;

    @include media("<sm") {
        width: calc(100% - rem(60px));
    }

    &.expanded {
        opacity: 1;
        visibility: initial;
        align-items: flex-end;
        padding: 0;
        gap: 1rem;
    }

    &__body {
        display: flex;
        opacity: 0;
        flex-grow: 1;
        flex-direction: column;
        width: 500px;
        max-width: 100%;
        margin: 0;
        padding: 1.5rem rem(75px);
        color: $color-white;
        transition: all 0.5s;

        @include media("<sm") {
            padding: 1rem;
        }

        .expanded & {
            opacity: 1;
            overflow-y: auto;
        }

        p {
            @extend .heading-callout-copy;
            color: $color-white;
            margin-top: 0;
            display: flex;
            flex-direction: column;
        }

    }

    &__details {
        transition: all 0.5s;
        transform: translateY(-40px);

        +.panel__details {}

        &__heading {
            margin-top: 0;
            margin: 0 0 1.5rem 0;
            transition: all 0.5s;
            transform: translateY(-40px);

            .expanded & {
                transform: translateY(0);
            }
        }

        &__acquisition-info {
            margin-bottom: 1.5rem;

            h3, p {
                margin: 0;
            }
        }

        h2 {
            color: $color-primary;
            @extend .heading-xsmall;
        }

        h3 {
            color: $color-white;
            @extend .heading-small;
        }

        .expanded & {
            transform: translateY(40px);
        }
    }

    &__description {
        transition: all 0.5s;
        transform: translateY(-40px);

        .expanded & {
            transform: translateY(40px);
        }
    }
}