@mixin icon($color: $color-dark) {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: rem(50px);
    height: rem(50px);
    padding: 0;
    border: none;
    border-radius: 50%;
    color: $color;
    background-color: transparent;

    >svg {
        font-size: 2rem;
        vertical-align: middle;
    }
}

@mixin icon-outline($color: $color-dark) {
    @include icon($color);
    position: relative;
    border: 3px solid;
    border-color: $color;
    background-color: transparent;

    >svg {
        color: $color;
    }
}

@mixin icon-outline-alt($border-color: $color-dark, $color: $color-white) {
    @include icon($border-color);
    position: relative;
    outline: none;

    &:before {
        @include element-overlay;
        border: 3px solid;
        border-color: transparent $border-color transparent $border-color;
        border-radius: 50%;
        content: "";
        transform: rotate(45deg);
    }

    >svg {
        color: $color;
    }
}

.icon {
    @include icon;
    $this: &;

    @each $variant,
    $value in $theme-colors {
        &--#{$variant} {
            @include icon($value);

            &#{$this}--outline {
                @include icon-outline($value);

                &--alt {
                    @include icon-outline-alt($value);
                }
            }
        }
    }

    &#{$this}--outline {
        @include icon-outline;

        &--alt {
            @include icon-outline-alt;
        }
    }

    &--small {
        transform: scale(.8);
    }
}